import React from "react";
import {graphql, Link} from 'gatsby'
import Layout from "../components/layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { RichText } from 'prismic-reactjs';
import Icon from "../components/icon";
import IconLink from "../components/icon-link";
import Image from "react-bootstrap/Image";
import GatsbyLink from "../components/gatsby-link";
import CallToAction from "../components/call-to-action";
import Phone from "../components/phone";
import Email from "../components/email";

const ProductPage = ({ data, pageContext }) => {
  const product = data.prismicLeistungen;
  const ansprechpartner = data.prismicTeamDetail;
  return (
    <Layout title={RichText.asText(product.data.name.raw)}>
      <section className="text-left">
        <Row>
          <Col>
            <IconLink to="/leistungen" icon="FaArrowLeft" left size="sm">
              Leistungen
            </IconLink>
            <div className="d-flex flex-row align-items-center mt-4">
              <h1>{RichText.asText(product.data.name.raw)}</h1>
              <div className="homepage-product-icon ml-3 brush brush-center brush-contain">
                <Image src={product.data.icon.url} alt={product.data.icon.alt} width={80} height={80} className="icon-img"/>
              </div>
            </div>
            <RichText render={product.data.produktbeschreibung.raw} serializeHyperlink={GatsbyLink}/>
            <div className="d-flex flex-column align-items-center align-items-sm-start mt-4">
              {product.data.attachements.map((attachement, index) => (
                <a  key={`attachement-${index}`} href={attachement.attachement_link.url} target="_blank" rel="noreferrer">
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <div style={{fontSize: '2rem'}} className="mr-2">
                      <Icon name={attachement.attachement_icon}/>
                    </div>
                    <p style={{textTransform: "none"}} className="m-0">{attachement.attachement_name}</p>
                  </div>
                </a>
              ))}
            </div>
            <div className="d-flex flex-row my-5 align-items-start">
              <Link to={product.data.ansprechpartner.url}>
                <Image className="img-avatar mr-3" src={ansprechpartner.data.portrait.fixed.src} alt={RichText.asText(ansprechpartner.data.name.raw)} width={100}/>
              </Link>
              <div>
                <p className="color-medium" style={{ margin: '0 0 5px 0', textTransform: 'uppercase', fontSize: '1rem', fontWeight: 300 }}>{ansprechpartner.data.ansprache}</p>
                <h1 style={{ fontSize: '1.4rem' }}>{RichText.asText(ansprechpartner.data.name.raw)}</h1>
                <Email email={ansprechpartner.data.email}/><br/>
                <Phone phone={ansprechpartner.data.phone}/>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <section className="mb-5">
        <Row>
          <Col className="d-flex flex-column justify-content-center align-items-center flex-sm-row justify-content-sm-between">
            <IconLink to={pageContext.prev.url} icon="FaArrowLeft" left>
              <span style={{textTransform: "none"}}>{RichText.asText(pageContext.prev.data.name.raw)}</span>
            </IconLink>
            <IconLink to={pageContext.next.url} icon="FaArrowRight">
              <span style={{textTransform: "none"}}>{RichText.asText(pageContext.next.data.name.raw)}</span>
            </IconLink>
          </Col>
        </Row>
      </section>
      <CallToAction/>
    </Layout>
  )
}

export const query = graphql`
  query($uid: String!, $ansprechpartnerUid: String!) {
    prismicLeistungen(uid: { eq: $uid }) {
      data {
        ansprechpartner {
          url
          uid
        }
        icon {
          alt
          url
        }
        kontakt_button {
          url
        }
        kontakt_button_label
        kurzbeschreibung {
          raw
        }
        name {
          raw
        }
        produktbeschreibung {
          raw
        }
        attachements {
          attachement_icon
          attachement_link {
            url
          }
          attachement_name
        }
      }
    }
    prismicTeamDetail(uid: { eq: $ansprechpartnerUid }) {
      data {
        email
        phone
        name {
          raw
        }
        ansprache
        portrait {
          fixed {
            src
          }
        }
      }
    }
  }
`

export default ProductPage
